import { ChangeEvent, FormEvent, useState } from 'react';
import { useAuth } from '../../../AuthProvider';
import Button from '../../../components/Button';
import ButtonLink from '../../../components/ButtonLink';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import { forgotPage } from '../../../constants/pagesRoutesContants';
import styles from './style.module.scss';

function Login() {
    const { userLogin } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const setValueOnChange: any = {
        email: setEmail,
        password: setPassword,
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (email.length === 0 || password.length === 0) {
            return;
        }
        userLogin({
            email: email,
            password: password,
        });
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setValueOnChange[name](value);
    };

    return (
        <Card>
            <form onSubmit={handleSubmit} className={styles.container}>
                <h2>Connexion</h2>
                <Input
                    label="Email"
                    name="email"
                    icon="mail"
                    onChange={handleChange}
                />
                <Input
                    type="password"
                    label="Mot de passe"
                    name="password"
                    icon="password"
                    onChange={handleChange}
                />
                <div className={styles.action}>
                    <ButtonLink link={forgotPage.path} variant="secondaryLight">
                        {forgotPage.name}
                    </ButtonLink>
                    <Button type="submit">Se connecter</Button>
                </div>
            </form>
        </Card>
    );
}

export default Login;
