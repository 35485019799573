import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import JudicialEventInterface from '../../../../../../interfaces/JudicialEventInterface';
import { postJudicialCalendar } from '../../../../../../services/judicialCalendarService';
import styles from './style.module.scss';

interface Props {
    setModalOpened: (param: any) => any;
    clientCaseId: string;
    judicialEvent: JudicialEventInterface | null;
}

function FormJudicialCalendar(props: Props) {
    const { setModalOpened, clientCaseId, judicialEvent } = props;
    const today = new Date();
    const [data, setData] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        setData(judicialEvent || {});
    }, [setData, judicialEvent]);

    const { mutate } = useMutation({
        mutationFn: async (newJudicialEvent: JudicialEventInterface) => {
            if (!newJudicialEvent) {
                return null;
            }
            const { data } = await postJudicialCalendar(newJudicialEvent);
            return data;
        },
    });

    const handleOnJudicialEventSave = (value: any) => {
        const newJudicialEvent = {
            title: data.title,
            date: data.date,
            description: data.description,
            clientCaseId: clientCaseId,
        };
        mutate(newJudicialEvent);
        setModalOpened(false);
    };
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const newData = data;
        newData[name] = value;
        setData(newData);
    };

    return (
        <div className={styles.body}>
            <div className={styles.eventModalBody}>
                <Input
                    value={data.title}
                    label="Évènement :"
                    name="title"
                    onChange={handleChange}
                />
                <Input
                    label="Date :"
                    type="datetime-local"
                    value={today.toLocaleString()}
                    name="date"
                    onChange={handleChange}
                />
                <Input
                    label="Description :"
                    value={data.description}
                    name="description"
                    onChange={handleChange}
                />
            </div>
            <div className={styles.eventModalAction}>
                <Button
                    onClick={() => {
                        setModalOpened(false);
                    }}
                >
                    Annuler
                </Button>
                <Button onClick={handleOnJudicialEventSave}>Valider</Button>
            </div>
        </div>
    );
}

export default FormJudicialCalendar;
