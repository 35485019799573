import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import Button from '../../../../../components/Button';
import FaIcon from '../../../../../components/Icons/FaIcon';
import Textaera from '../../../../../components/Textaera';
import { updateCase } from '../../../../../services/caseService';
import CaseType from '../../../../../types/CaseType';
import styles from './style.module.scss';

interface Props {
    clientCase: CaseType;
}

function CaseDescription(props: Props) {
    const { clientCase } = props;
    const [editDescription, setEditDescription] = useState(false);
    const [description, setDescription] = useState('');
    useEffect(() => {
        setDescription(clientCase.description);
    }, [clientCase]);

    const { mutate: saveDescription } = useMutation({
        mutationFn: async (description: string) => {
            if (description) {
                const requestData = { ...clientCase };
                requestData.description = description;
                const { data } = await updateCase(requestData);
                return data;
            }
        },
    });

    const handleEditDescription = () => {
        setEditDescription(true);
    };
    const handleChangeDescription = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setDescription(value);
    };
    const handleSaveDescription = () => {
        saveDescription(description);
        setEditDescription(false);
    };
    return (
        <div className={styles.infoContainer}>
            <h2 className={styles.titleAction}>
                <span>Description:</span>
                {!editDescription ? (
                    <Button onClick={handleEditDescription}>
                        <FaIcon>fa-edit</FaIcon>
                        Modifier
                    </Button>
                ) : (
                    <Button onClick={handleSaveDescription}>
                        <FaIcon>fa-save</FaIcon>
                        Enregistrer
                    </Button>
                )}
            </h2>
            {!editDescription ? (
                <pre>{description}</pre>
            ) : (
                <Textaera
                    onChange={handleChangeDescription}
                    name={'description'}
                    value={description}
                />
            )}
        </div>
    );
}

export default CaseDescription;
