import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../../AuthProvider';
import ButtonLink from '../../../../components/ButtonLink';
import Loader from '../../../../components/Loader';
import NotFound from '../../../../components/NotFound';
import {
    clientCasesIdPage,
    myCasesIdPage,
} from '../../../../constants/pagesRoutesContants';
import Role from '../../../../enums/RoleEnum';
import NavDataInterface from '../../../../interfaces/NavDataInterface';
import { fetchClientCasesByUserId } from '../../../../services/caseService';
import { postContactBookmark } from '../../../../services/contactService';
import { fetchUserById } from '../../../../services/userService';
import CaseType from '../../../../types/CaseType';
import LawyerCalendar from '../../../lawyers/lawyer/calendar';
import styles from './style.module.scss';

interface Props {
    setToolbar: (param: NavDataInterface[]) => void;
}

function ContactDetails(props: Props) {
    // ajouter une page de détails des étapes du dossier
    const { setToolbar } = props;
    const { pathname, hash } = useLocation();
    const { user: me } = useAuth();
    const userId = hash.replace('#', '');
    const page: string = pathname.replace('/' + userId, '');

    const { data: user, isPending } = useQuery({
        queryKey: ['getUser', userId],
        queryFn: async () => {
            if (!userId) {
                return null;
            }
            const { data } = await fetchUserById(userId);
            return data;
        },
    });

    const { data: cases, isPending: clientCasePending } = useQuery({
        queryKey: ['getClientCaseByUserId', userId],
        queryFn: async () => {
            if (!userId) {
                return null;
            }
            const { data } = await fetchClientCasesByUserId(userId);
            return data;
        },
    });

    const { mutate } = useMutation({
        mutationFn: async (id: string) => {
            const result = await postContactBookmark(id);
            return result;
        },
    });

    useEffect(() => {
        const handleClose = (event: React.MouseEvent) => {
            document.location = page;
        };

        const handleClickFavori = (event: React.MouseEvent) => {
            mutate(userId);
        };
        const caseToolbar: NavDataInterface[] = [
            {
                id: 'caseToolbar1',
                iconType: 'regular',
                icon: 'fa-star',
                label: 'Mettre en Favori',
                onClick: handleClickFavori,
                isDisabled: !user,
            },
            {
                id: 'caseToolbar9',
                icon: 'fa-remove',
                label: 'Fermer',
                onClick: handleClose,
                position: 'end',
            },
        ];

        setToolbar(caseToolbar);
    }, [page, setToolbar, user, mutate, userId]);

    if (isPending) {
        return <Loader />;
    }
    if (!user) {
        return <NotFound />;
    }

    return (
        <div className={styles.body}>
            <div className={styles.userInfoContainer}>
                <div className={styles.userProfile}>
                    <img
                        src="https://via.placeholder.com/150"
                        alt="UserProfilePicture"
                        className={styles.profilePicture}
                    />
                    <h2 className={styles.userName}>
                        {user.firstName} {user.lastName}
                    </h2>
                </div>
                <div className={styles.userDetails}>
                    <table>
                        <tbody>
                            {user.isLawyer && (
                                <tr>
                                    <td>
                                        <strong>Cabinet :</strong>
                                    </td>

                                    <td>{user.lawyer.lawfirm}</td>
                                </tr>
                            )}
                            <tr>
                                <td>
                                    <strong>Adresse :</strong>
                                </td>
                                <td>
                                    {user.isLawyer
                                        ? user.lawyer.address
                                        : user.address}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Email :</strong>
                                </td>
                                <td>
                                    {user.isLawyer
                                        ? user.lawyer.email
                                        : user.email}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Téléphone :</strong>
                                </td>
                                <td>
                                    {user.isLawyer
                                        ? user.lawyer.phone
                                        : user.phone}
                                </td>
                            </tr>
                            {!clientCasePending && cases && (
                                <tr>
                                    <td>
                                        <strong>
                                            Dossiers avec ce contact :
                                        </strong>
                                    </td>
                                    <td>
                                        <ul>
                                            {cases.map(
                                                (clientCase: CaseType) => (
                                                    <li key={clientCase.id}>
                                                        <ButtonLink
                                                            variant="primaryLight"
                                                            link={
                                                                (clientCase.userId ===
                                                                me?.id
                                                                    ? myCasesIdPage.path
                                                                    : clientCasesIdPage.path) +
                                                                clientCase.id
                                                            }
                                                        >
                                                            {clientCase.caseName ||
                                                                clientCase.caseRef}
                                                        </ButtonLink>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {user.role === Role.Lawyer && (
                <LawyerCalendar lawyer={user.lawyer} />
            )}
        </div>
    );
}

export default ContactDetails;
