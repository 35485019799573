import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, FormEvent, useState } from 'react';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import RegisterInterface from '../../../interfaces/RegisterInterface';
import { register } from '../../../services/accountService';
import styles from './style.module.scss';

function Register() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordChecked, setIsPasswordChecked] = useState<boolean>();
    const setValueOnChange: any = {
        email: setEmail,
        password: setPassword,
        firstname: setFirstName,
        lastname: setLastName,
    };
    const { mutate } = useMutation({
        mutationFn: async (data: RegisterInterface) => {
            return await register(data);
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isPasswordChecked || email.length === 0 || password.length === 0) {
            return;
        }
        mutate({
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            firstName: firstName,
            lastName: lastName,
        });
    };

    const handleCheckPasswordChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target;
        setIsPasswordChecked(password.length > 0 && password === value);
        setConfirmPassword(value);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setValueOnChange[name](value);
    };

    // const [userType, setUserType] = useState("");
    // const handleUserTypeChange = (event: any) => {
    //   setUserType(event.target.value);
    // };
    // const userTypeOptions: OptionInterface[] = [
    //   {
    //     id: "1",
    //     label: "Particulier",
    //     name: "individual",
    //     value: "individual",
    //   },
    //   {
    //     id: "2",
    //     label: "Entreprise",
    //     name: "company",
    //     value: "company",
    //   },
    // ];

    return (
        <Card>
            <form
                action=""
                onSubmit={handleSubmit}
                className={styles.container}
            >
                <h2>Inscription</h2>
                {/* <Select
          options={userTypeOptions}
          name="userType"
          label="Type d'utilisateur"
          onChange={handleUserTypeChange}
        />
        {userType === "2" && (
          <>
            <Input
              label="Nom de l'entreprise"
              name="companyName"
              onChange={handleChange}
            />
            <Input label="Siren" name="siren" onChange={handleChange} />
          </>
        )} */}
                <Input label="Nom" name="lastname" onChange={handleChange} />
                <Input
                    label="Prénom"
                    name="firstname"
                    onChange={handleChange}
                />
                <Input label="Email" name="email" onChange={handleChange} />
                <Input
                    type="password"
                    label="Mot de passe"
                    name="password"
                    onChange={handleChange}
                />
                <Input
                    type="password"
                    label="Vérifier le mot de passe"
                    name="password"
                    onChange={handleCheckPasswordChange}
                />
                <div className={styles.action}>
                    <Button type="submit">S'inscrire</Button>
                </div>
            </form>
        </Card>
    );
}

export default Register;
