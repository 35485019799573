import { useMutation, useQuery } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Autocomplete from '../../../../components/Autocomplete';
import Input from '../../../../components/Input';
import Loader from '../../../../components/Loader';
import TextEditor from '../../../../components/TextEditor';
import { notePage } from '../../../../constants/pagesRoutesContants';
import NavDataInterface from '../../../../interfaces/NavDataInterface';
import OptionInterface from '../../../../interfaces/OptionInterface';
import { fetchClientCases } from '../../../../services/caseService';
import { fetchContact } from '../../../../services/contactService';
import {
    deleteNote,
    fetchNoteById,
    postNote,
    updateNote,
} from '../../../../services/noteService';
import CaseType from '../../../../types/CaseType';
import NoteFormType from '../../../../types/NoteFormType';
import UserType from '../../../../types/UserType';
import styles from './style.module.scss';

interface Props {
    setToolbar: (param: NavDataInterface[]) => void;
}

function NoteDetails(props: Props) {
    // ajouter une page de détails des étapes du dossier
    const { setToolbar } = props;
    const { hash } = useLocation();
    const noteId = hash.replace('#', '');
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [clientCaseId, setClientCaseId] = useState<string | undefined>(
        undefined
    );
    const [clientId, setClientId] = useState<string | undefined>(undefined);

    const { data: clientsCases, isPending: clientCaseIsPending } = useQuery({
        queryKey: ['getClientsCases'],
        queryFn: async () => {
            const { data } = await fetchClientCases();
            return data;
        },
    });

    const { data: contacts, isPending: contactIsPending } = useQuery({
        queryKey: ['getContacts'],
        queryFn: async () => {
            const { data } = await fetchContact();
            return data;
        },
    });

    const { data: note, isPending } = useQuery({
        queryKey: ['getUser', noteId],
        queryFn: async () => {
            if (noteId?.length > 0) {
                const { data } = await fetchNoteById(noteId);
                return data;
            }
            return null;
        },
    });

    const { mutate: saveNote } = useMutation({
        mutationFn: async (note: NoteFormType) => {
            if (!!noteId && noteId.length > 0) {
                const result = await updateNote(noteId, note);
                return result;
            } else {
                const result = await postNote(note);
                return result;
            }
        },
    });

    const { mutate: deleteThisNote } = useMutation({
        mutationFn: async () => {
            if (!!noteId && noteId.length > 0) {
                await deleteNote(noteId);
            }
        },
    });

    useEffect(() => {
        const handleClose = (event: React.MouseEvent) => {
            document.location = notePage.path;
        };

        const handleClickSave = (event: React.MouseEvent) => {
            const note: NoteFormType = {
                title: title,
                text: text,
                clientCaseId: clientCaseId,
                clientId: clientId,
            };
            saveNote(note);
        };
        const handleClickDelete = (event: React.MouseEvent) => {
            deleteThisNote();
        };
        const caseToolbar: NavDataInterface[] = [
            {
                id: 'caseToolbar1',
                iconType: 'regular',
                icon: 'fa-save',
                label: 'Enregistrer',
                onClick: handleClickSave,
            },
            {
                id: 'deleteNote',
                label: 'Supprimer',
                icon: 'fa-trash',
                onClick: handleClickDelete,
            },
            {
                id: 'caseToolbar9',
                icon: 'fa-remove',
                label: 'Fermer',
                onClick: handleClose,
                position: 'end',
            },
        ];

        setToolbar(caseToolbar);
    }, [
        setToolbar,
        noteId,
        title,
        text,
        clientCaseId,
        clientId,
        saveNote,
        deleteThisNote,
    ]);

    const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setTitle(value);
    };
    const onTextChange = (value: any) => {
        setText(value);
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setClientCaseId(undefined);
        setClientId(undefined);
    };
    const onClientCaseSelect = (value?: OptionInterface) => {
        setClientCaseId(value?.data.id);
        setClientId(value?.data.userId);
    };
    const onContactSelect = (value?: OptionInterface) => {
        setClientId(value?.data.id);
    };

    useEffect(() => {
        setTitle(note?.title);
        setText(note?.text);
    }, [note]);

    const clientsCasesOptions = clientsCases?.map((clientCase: CaseType) => {
        const item: OptionInterface = {
            label: clientCase.caseName || clientCase.caseRef,
            name: clientCase.caseName || clientCase.caseRef,
            id: clientCase.id,
            data: clientCase,
        };
        return item;
    });

    const contactsOptions = contacts?.map((contact: UserType) => {
        const item: OptionInterface = {
            label: contact.firstName + ' ' + contact.lastName,
            name: contact.firstName + ' ' + contact.lastName,
            id: contact.id,
            data: contact,
        };
        return item;
    });

    if (isPending) {
        return <Loader />;
    }

    return (
        <div className={styles.body}>
            <Input
                label="Titre"
                name="name"
                placeholder="Nouvelle note"
                onChange={onTitleChange}
                value={title}
            />
            <Autocomplete
                options={clientsCasesOptions}
                label="Dossier"
                onOptionClick={onClientCaseSelect}
                onChange={onChange}
                name="clientCase"
                value={note?.clientCaseId}
                isDataLoading={clientCaseIsPending}
            />
            <Autocomplete
                options={contactsOptions}
                label="Client"
                onOptionClick={onContactSelect}
                disabled={clientCaseId !== undefined}
                value={note?.clientId || clientId}
                onChange={onChange}
                name="client"
                isDataLoading={contactIsPending}
            />
            <TextEditor onChange={onTextChange} value={text} />
        </div>
    );
}

export default NoteDetails;
