import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import Chip from '../../../../components/Chip';
import SearchBySpeciality from '../../../../components/SearchBar/SearchBySpeciality';
import {
    addMySpeciality,
    deleteMySpeciality,
} from '../../../../services/specialityService';
import specialityType from '../../../../types/SpecialityType';
import styles from './style.module.scss';

interface Props {
    specialities: specialityType[];
}

function SpecialitiesSettings(props: Props) {
    const { specialities } = props;
    const [lawyerSpecialities, setLawyerSpecialities] = useState<
        specialityType[]
    >([]);

    useEffect(() => {
        setLawyerSpecialities(specialities);
    }, [specialities]);

    const { mutate: addSpeciality } = useMutation({
        mutationFn: async (id: string) => {
            if (id.length > 0) {
                const result = await addMySpeciality(id);
                return result;
            }
        },
    });
    const { mutate: deleteSpeciality } = useMutation({
        mutationFn: async (id: string) => {
            if (id.length > 0) {
                const result = await deleteMySpeciality(id);
                return result;
            }
        },
    });

    const onOptionSelected = (speciality: specialityType | null) => {
        const lawyerIdSpecialities = lawyerSpecialities.map(
            (spe: specialityType) => {
                return spe.id;
            }
        );
        if (speciality) {
            if (!lawyerIdSpecialities.includes(speciality.id)) {
                const newSpeciality = [...lawyerSpecialities, speciality];
                setLawyerSpecialities(newSpeciality);
            }
            addSpeciality(speciality.id);
        }
    };
    const onDeleteSpeciality = (
        event: MouseEvent,
        speciality: specialityType
    ) => {
        const newSpeciality = lawyerSpecialities.filter(
            (spe: specialityType) => {
                return spe.id !== speciality.id;
            }
        );
        setLawyerSpecialities(newSpeciality);
        deleteSpeciality(speciality.id);
    };

    return (
        <div>
            <SearchBySpeciality
                onOptionSelected={onOptionSelected}
                reset={true}
            />
            <div className={styles.listSpecialities}>
                {lawyerSpecialities.map((speciality: specialityType) => (
                    <Chip
                        key={speciality.id}
                        onDelete={onDeleteSpeciality}
                        data={speciality}
                    >
                        {speciality.name}
                    </Chip>
                ))}
            </div>
        </div>
    );
}

export default SpecialitiesSettings;
