import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import Button from '../../../../components/Button';
import AddressForm from '../../../../components/FormEditor/AddressForm';
import PhoneForm from '../../../../components/FormEditor/PhoneForm';
import Input from '../../../../components/Input';
import { updateMyLawfirm } from '../../../../services/lawfirmService';
import LawfirmType from '../../../../types/LawfirmType';
import styles from './style.module.scss';

interface Props {
    lawfirm: LawfirmType | null;
}

function LawfirmInformationSettings(props: Props) {
    const { lawfirm } = props;
    const [lawfirmData, setUserData] = useState<LawfirmType | null>();

    useEffect(() => {
        setUserData(lawfirm);
    }, [lawfirm]);

    const { mutate: saveLawfirm } = useMutation({
        mutationFn: async () => {
            if (lawfirmData) {
                const result = await updateMyLawfirm(lawfirmData);
                return result;
            }
        },
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (lawfirmData) {
            const data: { [key: string]: any } = { ...lawfirmData };
            Object.keys(lawfirmData).forEach((key: string) => {
                if (key === name) {
                    data[name] = value;
                }
            });
            setUserData(data as LawfirmType);
        }
    };

    const handleSave = () => {
        saveLawfirm();
    };

    return (
        <div>
            <h2>Informations</h2>
            <PhoneForm value={lawfirm?.phone} onChange={handleChange} />
            <AddressForm data={lawfirm} onChange={handleChange} />
            <Input
                label="Description"
                value={lawfirm?.description}
                onChange={handleChange}
            />
            <div className={styles.btn} onClick={handleSave}>
                <Button>Enregistrer</Button>
            </div>
        </div>
    );
}

export default LawfirmInformationSettings;
