import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import ResetPasswordInterface from '../../../interfaces/ResetPasswordInterface';
import { resetPassword } from '../../../services/accountService';
import styles from './style.module.scss';

function ResetPassword() {
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email') || '';
    const token = searchParams.get('token') || '';
    const [password, setPassword] = useState('');

    const { mutate } = useMutation({
        mutationFn: async (resetPasswordData: ResetPasswordInterface) => {
            const { data } = await resetPassword(resetPasswordData);
            return data;
        },
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setPassword(value);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (
            password.length === 0 ||
            email?.length === 0 ||
            token?.length === 0
        ) {
            return;
        }

        const resetPasswordData: ResetPasswordInterface = {
            email: email,
            newPassword: password,
            resetCode: token.replaceAll(' ', '+'),
        };
        mutate(resetPasswordData);
    };

    return (
        <div className={styles.body}>
            <Card className={styles.container}>
                <h1>Nouveau mot de passe</h1>
                <Input
                    type="password"
                    label="Mot de passe"
                    name="password"
                    onChange={handleChange}
                />
                <div className={styles.action}>
                    <Button onClick={handleSubmit}>Envoyer</Button>
                </div>
            </Card>
        </div>
    );
}

export default ResetPassword;
