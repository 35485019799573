import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import ChangePasswordInterface from '../../../../interfaces/ChangePasswordInterface';
import { changePassword } from '../../../../services/accountService';
import styles from './style.module.scss';

function ChangePasswordSettings() {
    const [passwordData, setPasswordData] = useState<ChangePasswordInterface>({
        currentPassword: '',
        newPassword: '',
    });

    const { mutate: save } = useMutation({
        mutationFn: async (data: ChangePasswordInterface) => {
            const result = await changePassword(data);
            return result;
        },
    });
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;

        const inputName = name as 'currentPassword' | 'newPassword';
        const newPasswordData = {
            ...passwordData,
        };
        newPasswordData[inputName] = value;
        setPasswordData(newPasswordData);
    };
    const handleClick = () => {
        save(passwordData);
    };

    return (
        <div>
            <h2>Changer le mot de passe</h2>
            <Input
                type="password"
                name="currentPassword"
                label="Mot de passe actuel"
                onChange={handleChange}
                value={passwordData.currentPassword}
            />
            <Input
                type="password"
                name="newPassword"
                label="Nouveau mot de passe"
                onChange={handleChange}
                value={passwordData.newPassword}
            />
            <div className={styles.btn}>
                <Button onClick={handleClick}>Enregistrer</Button>
            </div>
        </div>
    );
}

export default ChangePasswordSettings;
