import { useAuth } from '../../../AuthProvider';
import Divider from '../../../components/Divider';
import Input from '../../../components/Input';
import Role from '../../../enums/RoleEnum';
import ChangePasswordSettings from './changePassword';
import LawyerBar from './lawyerBar';
import PersonalInformationSettings from './personalInformationSettings';
import SpecialitiesSettings from './specialitiesSettings';
import styles from './style.module.scss';
import WidgetsSettings from './widgetsSettings';

function Settings() {
    const { user } = useAuth();

    return (
        <div className={styles.container}>
            <PersonalInformationSettings user={user} />

            {user?.role === Role.Lawyer && (
                <>
                    <Divider />
                    <h2>Information professionnelle</h2>
                    <SpecialitiesSettings
                        specialities={user?.lawyer?.specialities}
                    />
                    <LawyerBar lawyerBarId={user?.lawyer?.lawyerBarId} />
                </>
            )}
            <Divider />
            <div>
                <h2>Style</h2>
                <Input type="file" label="Photo de profil" />
                <Input type="file" label="Photo de couverture" />
            </div>
            <Divider />
            <WidgetsSettings />
            <Divider />
            <ChangePasswordSettings />
        </div>
    );
}

export default Settings;
