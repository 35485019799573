import Input from '../../Input';
import styles from './style.module.scss';

interface Props {
    onChange?: (param: any) => any;
    data?: any;
}

function EmailForm(props: Props) {
    const { onChange, data } = props;

    return (
        <div className={styles.email}>
            <div className={styles.input}>
                <Input
                    name={'email'}
                    label="email"
                    type="email"
                    value={data?.email}
                    onChange={onChange}
                />
            </div>
            <div className={styles.input}>
                <Input
                    name={'checkEmail'}
                    label="Vérification email"
                    type="email"
                    value={data?.checkEmail}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}

export default EmailForm;
