import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import Button from '../../../../components/Button';
import AddressForm from '../../../../components/FormEditor/AddressForm';
import IdentityForm from '../../../../components/FormEditor/IdentityForm';
import PhoneForm from '../../../../components/FormEditor/PhoneForm';
import { updateUser } from '../../../../services/userService';
import UserType from '../../../../types/UserType';
import styles from './style.module.scss';

interface Props {
    user: UserType | null;
}

function PersonalInformationSettings(props: Props) {
    const { user } = props;
    const [userData, setUserData] = useState<UserType | null>();

    useEffect(() => {
        setUserData(user);
    }, [user]);

    const { mutate: saveUser } = useMutation({
        mutationFn: async () => {
            if (userData) {
                const result = await updateUser(userData);
                return result;
            }
        },
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (userData) {
            const data: { [key: string]: any } = { ...userData };
            Object.keys(userData).forEach((key: string) => {
                if (key === name) {
                    data[name] = value;
                }
            });
            setUserData(data as UserType);
        }
    };

    const handleSave = () => {
        saveUser();
    };

    return (
        <div>
            <h2>Informations personnelles</h2>
            <IdentityForm data={userData} onChange={handleChange} />
            <PhoneForm value={userData?.phone} onChange={handleChange} />
            <AddressForm data={userData} onChange={handleChange} />
            <div className={styles.btn}>
                <Button onClick={handleSave}>Enregistrer</Button>
            </div>
        </div>
    );
}

export default PersonalInformationSettings;
