import axios, { AxiosError } from 'axios';
import { createEndpoint } from '../constants/endpointsContants';
import { requestStatus } from '../constants/requestStatusContants';
import { storageItems } from '../constants/storageItemsContants';
import { logout } from './accountService';
const headersConfig = { 'Content-Type': 'application/json' };
interface Request {
    endpoint: string;
    id?: string | number;
    data?: any;
    params?: any;
    token?: string;
    config?: any;
}

async function getRequest(requestData: Request) {
    const { endpoint, id = '', params } = requestData;
    const url = createEndpoint([endpoint, id.toString()]);
    try {
        const cred = localStorage.getItem(storageItems.user);
        let config: { [key: string]: any } = {};
        if (cred) {
            const token = JSON.parse(cred).accessToken;
            config = {
                headers: { ...headersConfig, Authorization: `Bearer ${token}` },
            };
        }
        const res = await axios.get(url, { ...config, params });
        if (res.status !== 200) {
            throw new Error(res.statusText);
        }

        return res;
    } catch (err) {
        const error = err as AxiosError;
        disconnectAfterTokenExpired(error.response?.status);
        console.error(error);
        throw new Error('Failed to fetch data');
    }
}

async function postRequest(requestData: Request) {
    const { endpoint, id = '', data } = requestData;
    const url = createEndpoint([endpoint, id.toString()]);

    try {
        const cred = localStorage.getItem(storageItems.user);
        let config: { [key: string]: any } = {};
        if (cred) {
            const token = JSON.parse(cred).accessToken;
            config = {
                headers: { ...headersConfig, Authorization: `Bearer ${token}` },
            };
        }
        const res = await axios.post(url, data, config);
        if (res.status !== 200) {
            throw new Error(res.statusText);
        }
        return res;
    } catch (err) {
        const error = err as AxiosError;
        disconnectAfterTokenExpired(error.response?.status);
        console.error(error);
        throw new Error('Failed to fetch data');
    }
}

async function putRequest(requestData: Request) {
    const { endpoint, id = '', data } = requestData;
    const url = createEndpoint([endpoint, id.toString()]);
    try {
        const cred = localStorage.getItem(storageItems.user);
        let config: { [key: string]: any } = {};
        if (cred) {
            const token = JSON.parse(cred).accessToken;

            config = {
                headers: { ...headersConfig, Authorization: `Bearer ${token}` },
            };
        }

        const res = await axios.put(url, data, config);
        if (res.status !== 200) {
            throw new Error(res.statusText);
        }
        return res;
    } catch (err) {
        const error = err as AxiosError;
        disconnectAfterTokenExpired(error.response?.status);
        console.error(error);
        throw new Error('Failed to fetch data');
    }
}

async function deleteRequest(requestData: Request) {
    const { endpoint, id = '' } = requestData;
    if (id) {
        const url = createEndpoint([endpoint, id.toString()]);
        try {
            const cred = localStorage.getItem(storageItems.user);
            let config: { [key: string]: any } = {};
            if (cred) {
                const token = JSON.parse(cred).accessToken;
                config = {
                    headers: {
                        ...headersConfig,
                        Authorization: `Bearer ${token}`,
                    },
                };
            }
            const res = await axios.delete(url, config);
            if (res.status !== 200) {
                throw new Error(res.statusText);
            }
            return res;
        } catch (err) {
            const error = err as AxiosError;
            disconnectAfterTokenExpired(error.response?.status);
            console.error(error);
            throw new Error('Failed to fetch data');
        }
    }
}

const request = {
    get: getRequest,
    post: postRequest,
    put: putRequest,
    delete: deleteRequest,
};

function disconnectAfterTokenExpired(status: number | undefined) {
    if (status === requestStatus.Unauthorized) {
        logout();
    }
}

export default request;
