import { useMutation, useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import Checkbox from '../../../../components/Checkbox';
import LoaderDot from '../../../../components/LoaderDot';
import OptionInterface from '../../../../interfaces/OptionInterface';
import {
    AddMyWidget,
    fetchMyWidgets,
    fetchWidgets,
} from '../../../../services/widgetService';
import WidgetType from '../../../../types/WidgetType';

function WidgetsSettings() {
    const [widgetList, setWidgetList] = useState([]);

    const { isPending: isWidgetPending, data: widgets } = useQuery({
        queryKey: ['widgets'],
        queryFn: async () => {
            try {
                const { data } = await fetchWidgets();
                return data || [];
            } catch (error) {}
            return [];
        },
    });
    const { isPending: isMyWidgetPending } = useQuery({
        queryKey: ['myWidgets'],
        queryFn: async () => {
            try {
                const { data } = await fetchMyWidgets();
                setWidgetList(data);
                return data || [];
            } catch (error) {}
            return [];
        },
    });

    const { mutate } = useMutation({
        mutationFn: async (id: string) => {
            if (id?.length > 0) {
                const result = await AddMyWidget(id);
                setWidgetList(result.data);
                return result;
            }
        },
    });

    const widgetsOptions: OptionInterface[] = widgets?.map(
        (widget: WidgetType) => {
            return {
                label: widget.label,
                name: widget.id,
            };
        }
    );
    const myWidgetsOptions: OptionInterface[] = widgetList?.map(
        (widget: WidgetType) => {
            return {
                label: widget.label,
                name: widget.id,
            };
        }
    );

    const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        mutate(value);
    };

    if (isWidgetPending || isMyWidgetPending) {
        <LoaderDot />;
    }
    return (
        <div>
            <h2>Dashboard Widgets</h2>
            <Checkbox
                name="widget"
                options={widgetsOptions}
                checked={myWidgetsOptions}
                onChange={handleChangeCheckbox}
            />
        </div>
    );
}

export default WidgetsSettings;
