import Input from '../../Input';

interface Props {
    onChange?: (param: any) => any;
    name?: string;
    value?: string;
}

function PhoneForm(props: Props) {
    const { onChange, value = '', name = 'phone' } = props;
    return (
        <Input
            label="Téléphone"
            type="text"
            name={name}
            value={value}
            onChange={onChange}
            max={10}
        />
    );
}

export default PhoneForm;
