import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import LoginInterface from "../interfaces/LoginInterface";
import { storageItems } from "../constants/storageItemsContants";
import { requestStatus } from "../constants/requestStatusContants";
import AuthContextInterface from "../interfaces/AuthContextInterface";
import { fetchUser } from "../services/userService";
import UserType from "../types/UserType";
import Role from "../enums/RoleEnum";
import { login, logout } from "../services/accountService";

// Create a context for authentication

interface Props {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface
);

export const AuthProvider = (props: Props) => {
  const { children } = props;
  const isAuthenticated: boolean = !!localStorage.getItem(storageItems.user);
  const [isAuth, setIsAuth] = useState<boolean>(isAuthenticated);
  const [user, setUser] = useState<UserType | null>(null);

  useEffect(() => {
    if (!isAuthenticated) {
      logout();
      setIsAuth(false);
    }
  }, [isAuthenticated]);

  useQuery({
    queryKey: ["me", isAuth],
    queryFn: async () => {
      if (isAuthenticated) {
        const { status, data } = await fetchUser();
        if (status === requestStatus.OK) {
          setUser(data);
        }
        return data;
      }
      return null;
    },
  });

  const role: Role = user?.role || Role.User;

  const { mutate } = useMutation({
    mutationFn: async (data: LoginInterface) => {
      const result = await login(data);
      if (
        result.status === requestStatus.OK &&
        localStorage.getItem(storageItems.user)
      ) {
        setIsAuth(true);
      }
    },
  });

  const userLogin = (loginInfo: LoginInterface) => {
    mutate(loginInfo);
  };

  const userLogout = () => {
    if (isAuth) {
      logout();
      setIsAuth(false);
    }
  };

  const newValue = {
    isAuth,
    user,
    role,
    userLogin,
    userLogout,
  };

  return (
    <AuthContext.Provider value={newValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
