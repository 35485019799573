import Input from '../../Input';
import styles from './style.module.scss';

interface Props {
    onChange?: (param: any) => any;
    data?: any;
}

function AddressForm(props: Props) {
    const { onChange, data } = props;

    return (
        <div>
            <div className={styles.streetInfo}>
                <div className={styles.streetName}>
                    <Input
                        name={'streetName'}
                        label="Adresse"
                        type="text"
                        value={data?.streetName}
                        onChange={onChange}
                    />
                </div>
            </div>
            <div className={styles.cityInfo}>
                <div className={styles.zipCode}>
                    <Input
                        name={'zipCode'}
                        label="Code Postal"
                        type="text"
                        value={data?.zipCode}
                        onChange={onChange}
                        max={5}
                    />
                </div>
                <div className={styles.city}>
                    <Input
                        name={'city'}
                        label="Commune"
                        type="text"
                        value={data?.city}
                        onChange={onChange}
                    />
                </div>
                <div className={styles.country}>
                    <Input
                        name={'country'}
                        label="Pays"
                        type="text"
                        value={data?.country}
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default AddressForm;
