import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import Button from '../../../../../components/Button';
import FaIcon from '../../../../../components/Icons/FaIcon';
import Action from '../../../../../enums/ActionsEnum';
import { percentage } from '../../../../../helpers/number';
import { updateService } from '../../../../../services/serviceService';
import ServiceType from '../../../../../types/ServiceType';
import styles from './style.module.scss';

interface Props {
    service: ServiceType;
    serviceClick: (param: ServiceType) => void;
    deleteServiceClick: (param: ServiceType) => void;
}

function ServiceTableRow(props: Props) {
    const { service, serviceClick, deleteServiceClick } = props;
    const [serviceData, setServiceData] = useState<ServiceType | null>(null);
    useEffect(() => {
        setServiceData(service);
    }, [service]);

    const handleVisibleClick = (newService: ServiceType) => {
        newService = { ...service };
        newService.isVisible = !serviceData?.isVisible;
        setServiceData(newService);
        mutate({ action: Action.edit, data: newService });
    };

    const handleServiceClick = () => {
        serviceClick(service);
    };
    const handleDeleteServiceClick = () => {
        deleteServiceClick(service);
    };

    const { mutate } = useMutation({
        mutationFn: async (value: { action: Action; data: ServiceType }) => {
            const { data } = value;
            return await updateService(data);
        },
    });

    return (
        <tr>
            <td>{serviceData?.name}</td>
            <td>{serviceData?.duration} min</td>
            <td>{serviceData?.price}€</td>
            <td>
                {serviceData?.tva}% (
                {percentage(serviceData?.price || 0, serviceData?.tva || 0)}
                €)
            </td>
            <td>
                <div className={styles.action}>
                    <Button variant="primaryLight" onClick={handleVisibleClick}>
                        <FaIcon>
                            {serviceData?.isVisible ? 'fa-eye' : 'fa-eye-slash'}
                        </FaIcon>
                    </Button>
                    <Button variant="primaryLight" onClick={handleServiceClick}>
                        <FaIcon>fa-edit</FaIcon>
                    </Button>
                    <Button
                        variant="primaryLight"
                        onClick={handleDeleteServiceClick}
                        name={serviceData?.name}
                    >
                        <FaIcon>fa-trash</FaIcon>
                    </Button>
                </div>
            </td>
        </tr>
    );
}

export default ServiceTableRow;
