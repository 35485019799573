import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import Checkbox from '../../../../components/Checkbox';
import Input from '../../../../components/Input';
import ModalAction from '../../../../components/ModalAction';
import Action from '../../../../enums/ActionsEnum';
import OptionInterface from '../../../../interfaces/OptionInterface';
import { addService, updateService } from '../../../../services/serviceService';
import ServiceType from '../../../../types/ServiceType';
import styles from './style.module.scss';

interface Props {
    service: ServiceType | null;
    setModalOpened: (param: boolean) => void;
    modalOpened: boolean;
}

function ServiceSaveForm(props: Props) {
    const { service, setModalOpened, modalOpened } = props;
    const defaultTva = 20;

    const [serviceData, setServiceData] = useState<ServiceType | null>(null);
    useEffect(() => {
        setServiceData(service);
    }, [service]);

    const isVisibleOptions: OptionInterface[] = [
        {
            label: 'Visible',
            name: 'isVisible',
        },
    ];

    const actionsObject = {
        [Action.add]: { request: addService },
        [Action.edit]: { request: updateService },
    };

    const { mutate } = useMutation({
        mutationFn: async (value: {
            action: Action.add | Action.edit;
            data: ServiceType;
        }) => {
            const { action, data } = value;
            return await actionsObject[action].request(data);
        },
    });

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const newData: { [key: string]: any } = { ...serviceData };
        newData[name] = value;
        setServiceData(newData as ServiceType);
    };
    const [checked, setChecked] = useState<OptionInterface[]>(
        serviceData?.isVisible ? isVisibleOptions : []
    );

    const handleVisibilityChange = (
        event: ChangeEvent<HTMLInputElement>,
        options: OptionInterface[]
    ) => {
        const { name } = event.target;
        const isChecked = options.length === 1;
        setChecked(options);
        const newData: { [key: string]: any } = { ...serviceData };
        newData[name] = isChecked;
        setServiceData(newData as ServiceType);
    };

    const handleSaveServiceClick = (action: Action.add | Action.edit) => {
        if (serviceData) {
            const newService: ServiceType = {
                name: serviceData.name,
                price: serviceData.price,
                duration: serviceData.duration,
                tva:
                    serviceData.tva && serviceData.tva !== defaultTva
                        ? serviceData.tva
                        : defaultTva,
                isVisible: serviceData.isVisible,
            };
            mutate({ action: action, data: { ...serviceData, ...newService } });
            setModalOpened(false);
        }
    };

    return (
        <ModalAction
            opened={modalOpened}
            setOpened={setModalOpened}
            title="Modifier service"
            onClick={() => {
                handleSaveServiceClick(service ? Action.edit : Action.add);
            }}
        >
            <div className={styles.modalServiceBody}>
                <Input
                    label="Nom"
                    name="name"
                    onChange={handleInputChange}
                    value={serviceData?.name}
                />
                <div className={styles.modalServiceBodyNumber}>
                    <Input
                        type="number"
                        name="duration"
                        label="Durée en minutes"
                        onChange={handleInputChange}
                        value={serviceData?.duration}
                    />
                    <Input
                        type="number"
                        name="price"
                        label="Prix en €"
                        onChange={handleInputChange}
                        value={serviceData?.price}
                    />
                    <Input
                        type="number"
                        name="tva"
                        label="TVA en %"
                        value={defaultTva}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <Checkbox
                        name="isVisible"
                        options={isVisibleOptions}
                        checked={checked}
                        onChange={handleVisibilityChange}
                    />
                </div>
            </div>
        </ModalAction>
    );
}

export default ServiceSaveForm;
