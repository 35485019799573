import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import Input from '../../../../components/Input';
import { days } from '../../../../constants/calendarContants';
import { addAvailabilities } from '../../../../services/availabilityService';
import { postHolidays } from '../../../../services/holidaysService';
import AvailabilityType from '../../../../types/AvailabilityType';
import HolidaysType from '../../../../types/HolidaysType';
import DayAvailabilities from './dayAvailabilities';
import styles from './style.module.scss';

interface Props {
    data: AvailabilityType[];
}

function AvailabilitiesForm(props: Props) {
    const { data } = props;
    const [availabilities, setAvailabilities] = useState<AvailabilityType[]>(
        []
    );

    useEffect(() => {
        setAvailabilities(data);
    }, [data]);

    const { mutate: saveHolidays } = useMutation({
        mutationFn: async (data: HolidaysType) => {
            const result = await postHolidays(data);
            return result;
        },
    });
    const { mutate: saveAvailabilities } = useMutation({
        mutationFn: async (data: AvailabilityType[]) => {
            const result = await addAvailabilities(data);
            return result;
        },
    });

    const today = new Date();
    const [holidays, setHolidays] = useState<{ [key: string]: any }>({
        startDate: '',
        endDate: '',
    });

    const [isHolidays, setIsHolidays] = useState(false);
    const handleChangeHolidays = (value: any) => {
        const { selectedItems } = value;
        const newDate = { ...holidays };
        if (selectedItems?.length) {
            newDate['startDate'] = today.toISOString().split('T')[0];
        }
        setHolidays(newDate);
        setIsHolidays(!isHolidays);
        saveHolidays({
            startDate: new Date(holidays.startDate),
            endDate: new Date(holidays.endDate),
        });
    };
    const handleChangeHolidaysDate = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const newDate = { ...holidays };
        newDate[name] = value;
        setHolidays(newDate);
        saveHolidays({
            startDate: new Date(holidays.startDate),
            endDate: new Date(holidays.endDate),
        });
    };

    const handleChangeAvailability = (data: AvailabilityType) => {
        const newAvailabilities = [...availabilities];
        const lastValues = newAvailabilities.find((x) => x.day === data.day);
        if (lastValues) {
            const indexLastValue = newAvailabilities.indexOf(lastValues);
            newAvailabilities[indexLastValue] = data;
        } else {
            newAvailabilities.push(data);
        }
        setAvailabilities(newAvailabilities);
    };

    const handleClickSave = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const newDate = { ...holidays };
        newDate[name] = value;
        saveAvailabilities(availabilities);
    };

    const getDayAvailabilities = (day: number) => {
        if (availabilities) {
            return availabilities.find((x) => x.day === day);
        }
    };

    return (
        <div className={styles.card}>
            <div className={styles.title}>
                <span>Veuillez selectionner les jours travaillés :</span>
                <Checkbox
                    name="holidays"
                    options={[
                        {
                            name: 'holidays',
                            label: 'Vacances',
                            value: 'holidays',
                        },
                    ]}
                    onChange={handleChangeHolidays}
                />
                {isHolidays && (
                    <div className={styles.formHoliday}>
                        <Input
                            onChange={handleChangeHolidaysDate}
                            name={'startDate'}
                            label="Début de vacances"
                            type="date"
                            value={holidays.startDate}
                            disabled={!isHolidays}
                        />
                        <Input
                            onChange={handleChangeHolidaysDate}
                            name={'endDate'}
                            label="Fin de vacances"
                            type="date"
                            value={holidays.endDate}
                            disabled={!isHolidays}
                        />
                    </div>
                )}
            </div>

            <div className={styles.formAvailabilities}>
                {days.map((label, index) => (
                    <DayAvailabilities
                        day={{
                            label: label,
                            index: index,
                        }}
                        key={index}
                        dayAvailabilities={getDayAvailabilities(index)}
                        isHolidays={isHolidays}
                        onChange={handleChangeAvailability}
                    />
                ))}
            </div>
            <div>
                <Button onClick={handleClickSave}>Enregistrer</Button>
            </div>
        </div>
    );
}
export default AvailabilitiesForm;
