import { classNames } from '../../helpers/classNames';
import FaIcon from '../Icons/FaIcon';
import styles from './style.module.scss';

interface Props {
    label?: string;
    icon?: string;
    placeholder?: string;
    onChange?: (param: any) => any;
    onBlur?: (param: any) => any;
    inputClassNames?: any;
    value?: string;
    name?: string;
}

function Textaera(props: Props) {
    const {
        label,
        icon,
        placeholder,
        onChange,
        onBlur,
        inputClassNames = styles.input,
        value = '',
        name,
    } = props;

    return (
        <div className={classNames([styles.divInput])}>
            {label && (
                <>
                    <label className={classNames([styles.labelInput])}>
                        {icon && <FaIcon>{icon}</FaIcon>}
                        {label}
                    </label>
                </>
            )}
            <textarea
                className={classNames([styles.input, inputClassNames])}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                rows={5}
                name={name}
                defaultValue={value}
            ></textarea>
        </div>
    );
}

export default Textaera;
