import styles from './style.module.scss';
import { classNames } from '../../helpers/classNames';

interface Props {
    width?: string;
    height?: string;
    position?: string;
    variant?: 'primary' | 'secondary' | 'tertiary';
}

function Divider(props: Props) {
    const { variant = 'primary', width = '100%', height = '1px' } = props;

    const styleDivider = {
        width: width,
        height: height,
    };

    return (
        <div
            style={styleDivider}
            className={classNames([styles[variant]])}
        ></div>
    );
}

export default Divider;
