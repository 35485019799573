import Input from '../../Input';

interface Props {
    name: string;
    label: string;
    value?: string;
    onChange?: (param: any) => any;
}

function DateForm(props: Props) {
    const { name, label, value, onChange } = props;
    return (
        <div>
            <div>
                <Input
                    label={label}
                    name={name}
                    type="date"
                    onChange={onChange}
                    value={value}
                />
            </div>
        </div>
    );
}

export default DateForm;
