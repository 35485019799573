import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import userPhoto from '../../../assets/user.jpg';
import ButtonLink from '../../../components/ButtonLink';
import Card from '../../../components/Card';
import FaIcon from '../../../components/Icons/FaIcon';
import { lawyersIdPage } from '../../../constants/pagesRoutesContants';
import { classNames } from '../../../helpers/classNames';
import { fetchLawfirm } from '../../../services/lawfirmService';
import LawfirmType from '../../../types/LawfirmType';
import Lawfirms from '../page';
import styles from './style.module.scss';

function Lawfirm() {
    const params = useParams();

    const { isPending, error, data } = useQuery({
        queryKey: ['lawfirm', params.lawfirmId],
        queryFn: async () => {
            if (!params.lawfirmId) {
                return <Lawfirms />;
            }
            const { data } = await fetchLawfirm(params.lawfirmId);
            return data;
        },
    });

    const lawfirm: LawfirmType = data;
    if (isPending) return <div>Loading...</div>;

    if (error) return <div>An error has occurred: {error.message}</div>;

    const style: { [k: string]: any } = {};
    if (lawfirm.cover) {
        style.backgroundImage = `url(${lawfirm.cover})`;
    }

    const onImageError = (event: any) => {
        event.target.style = 'display:none';
    };

    return (
        <div className={classNames([styles.lawfirmDetails])}>
            <div className={styles.cover} style={style}>
                <div className={styles.photoContainer}>
                    <div className={styles.bodyId}>{lawfirm.name}</div>
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.bodyInfo}>
                    <div className={styles.bodyContact}>
                        <div>
                            <span className={styles.icon}>
                                <FaIcon>fa-map-location-dot</FaIcon>
                            </span>
                            <span>Adresse:</span>
                            <span>
                                {lawfirm.buildingNumber} {lawfirm.streetName}
                            </span>
                            <span>
                                {lawfirm.city} {lawfirm.zipCode}{' '}
                                {lawfirm.country}
                            </span>
                        </div>
                        <div>
                            <span className={styles.icon}>
                                <FaIcon>fa-phone</FaIcon>
                            </span>
                            <span>Téléphone:</span>
                            <span>
                                {lawfirm.phone?.length > 0
                                    ? lawfirm.phone
                                    : 'NC'}
                            </span>
                        </div>
                        <div>
                            <span className={styles.icon}>
                                <FaIcon>fa-envelope</FaIcon>
                            </span>
                            <span>Mail:</span>
                            <span>
                                {lawfirm.email?.length > 0
                                    ? lawfirm.email
                                    : 'NC'}
                            </span>
                        </div>
                    </div>
                    <Card className={styles.bodyDescription}>
                        <h3>Présentation : </h3>
                        <p>
                            Le Lorem Ipsum est simplement du faux texte employé
                            dans la composition et la mise en page avant
                            impression. Le Lorem Ipsum est le faux texte
                            standard de l'imprimerie depuis les années 1500,
                            quand un imprimeur anonyme assembla ensemble des
                            morceaux de texte pour réaliser un livre spécimen de
                            polices de texte.
                        </p>
                    </Card>
                    {lawfirm.lawyers.length > 0 && (
                        <Card className={styles.bodyLawyers}>
                            <h3>Nos Avocats : </h3>
                            <div className={styles.lawyerList}>
                                {lawfirm.lawyers.map((lawyer) => (
                                    <ButtonLink
                                        link={lawyersIdPage.path + lawyer.id}
                                        key={lawyer.id}
                                        variant={'secondaryLight'}
                                    >
                                        <div className={styles.lawyer}>
                                            <div className={styles.photoCard}>
                                                <img
                                                    src={
                                                        lawyer.photo ||
                                                        userPhoto
                                                    }
                                                    alt={`${
                                                        lawyer.firstName
                                                    } ${lawyer.lastName?.toLocaleUpperCase()}`}
                                                    onError={onImageError}
                                                    className={styles.photo}
                                                />
                                            </div>
                                            <span>
                                                {`${
                                                    lawyer.firstName
                                                } ${lawyer.lastName?.toLocaleUpperCase()}`}
                                            </span>
                                        </div>
                                    </ButtonLink>
                                ))}
                            </div>
                        </Card>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Lawfirm;
