import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, FormEvent, useState } from 'react';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import SearchByLawyer from '../../../components/SearchBar/SearchByLawyer';
import { lawyerRegistration } from '../../../services/lawyerService';
import LawyerType from '../../../types/LawyerType';
import styles from './style.module.scss';

function EnrrollLawyer() {
    const [email, setEmail] = useState('');
    const [lawyerValue, setLawyerValue] = useState<LawyerType | null>(null);

    const { mutate } = useMutation({
        mutationFn: async (email: string) => {
            if (lawyerValue) {
                const { data } = await lawyerRegistration({
                    email,
                    id: lawyerValue.id,
                });
                return data;
            }
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (email.length === 0) {
            return;
        }
        mutate(email);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setEmail(value);
    };

    return (
        <div className={styles.body}>
            <form onSubmit={handleSubmit}>
                <Card className={styles.container}>
                    <h1>S'enregistrer</h1>
                    <SearchByLawyer
                        onOptionSelected={setLawyerValue}
                        onChange={handleChange}
                    />
                    <Input label="Email" name="email" onChange={handleChange} />
                    <div className={styles.action}>
                        <Button type={'submit'}>{"S'enregistrer"}</Button>
                    </div>
                </Card>
            </form>
        </div>
    );
}

export default EnrrollLawyer;
