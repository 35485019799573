import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, FormEvent, useState } from 'react';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import { forgotPassword } from '../../../services/accountService';
import styles from './style.module.scss';

function ForgotPassword() {
    const [email, setEmail] = useState('');

    const { mutate } = useMutation({
        mutationFn: async (email: string) => {
            const { data } = await forgotPassword(email);
            return data;
        },
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setEmail(value);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (email.length === 0) {
            return;
        }
        mutate(email);
    };

    return (
        <div className={styles.body}>
            <Card className={styles.container}>
                <h1>Mot de passe oublié</h1>
                <Input label="Email" name="email" onChange={handleChange} />
                <div className={styles.action}>
                    <Button onClick={handleSubmit}>Envoyer</Button>
                </div>
            </Card>
        </div>
    );
}

export default ForgotPassword;
