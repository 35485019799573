import { useMutation } from '@tanstack/react-query';
import ModalAction from '../../../../components/ModalAction';
import { deleteService } from '../../../../services/serviceService';

interface Props {
    serviceId?: string;
    serviceName?: string;
    setModalOpened: (param: boolean) => void;
    modalOpened: boolean;
}

function ServiceDeleteConfirmation(props: Props) {
    const {
        serviceId = '',
        serviceName = '',
        setModalOpened,
        modalOpened,
    } = props;

    const handleDeleteServiceClick = () => {
        mutate();
        setModalOpened(false);
    };
    const { mutate } = useMutation({
        mutationFn: async () => {
            return await deleteService(serviceId);
        },
    });

    return (
        <ModalAction
            opened={modalOpened}
            setOpened={setModalOpened}
            onClick={() => {
                handleDeleteServiceClick();
            }}
        >
            <span>
                Voulez-vous vraiment supprimer le service {serviceName}?
            </span>
        </ModalAction>
    );
}

export default ServiceDeleteConfirmation;
