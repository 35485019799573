import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import {
    contactIdPage,
    messagesPage,
} from '../../../../constants/pagesRoutesContants';
import NavDataInterface from '../../../../interfaces/NavDataInterface';
import OptionInterface from '../../../../interfaces/OptionInterface';
import { postUserMessagesByRecipientId } from '../../../../services/messageService';
import styles from './style.module.scss';

interface Props {
    setToolbar: (param: NavDataInterface[]) => void;
    recipient: OptionInterface | null;
}

function NewConversation(props: Props) {
    const { recipient, setToolbar } = props;
    const [messageText, setMessageText] = useState<string>('');

    const { mutate } = useMutation({
        mutationFn: async (data) => {
            if (recipient?.id) {
                const result = await postUserMessagesByRecipientId(
                    recipient?.id,
                    messageText
                );
                return result;
            }
        },
    });

    const handleChangeMessage = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setMessageText(value);
    };

    useEffect(() => {
        const handleCloseConversation = (event: React.MouseEvent) => {
            document.location = messagesPage.path;
        };
        const toolbar: NavDataInterface[] = [
            {
                id: 'toolbarBtnClose',
                icon: 'fa-remove',
                label: 'Fermer',
                position: 'end',
                onClick: handleCloseConversation,
            },
            {
                id: 'toolbarBtn' + recipient?.id,
                icon: 'fa-user',
                label: recipient?.name || '',
                position: 'start',
                link: contactIdPage.path + recipient?.id,
            },
        ];
        setToolbar(toolbar);
    }, [recipient, setToolbar]);
    return (
        <div>
            <div className={styles.conversation}>
                <div className={styles.messages}></div>
                <div className={styles.action}>
                    <div className={styles.write}>
                        <Input onChange={handleChangeMessage} />
                    </div>
                    <div className={styles.send}>
                        <Button onClick={mutate}>Envoyer</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewConversation;
